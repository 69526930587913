@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: #020617 !important;
}
body {
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-RegularItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: loading;
}


@font-face {
  font-family: "Matter";
  src: url("./font/Matter-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-SemiBoldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-Heavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: loading;
}

@font-face {
  font-family: "Matter";
  src: url("./font/Matter-HeavyItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
  font-display: loading;
}


/* ANIMATION FOR LOADER */

.dot-flashing {
  position: relative;
  top: 1px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #ae8cfe;
  color: #ae8cfe;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .3s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
}

.dot-flashing::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #ae8cfe;
  color: #ae8cfe;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #ae8cfe;
  color: #ae8cfe;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0.75s;
}

@keyframes dotFlashing {
  0% {
    background-color: #ffffff;
  }
  50%,
  100% {
    background-color: #ae8cfe;

  }
}






.rolling {
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }

  49% {
    color: #000;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: #000;
  }
}





/* Slots CSS */
@import url("https://use.typekit.net/yjs2lsy.css");



.casinoName {
  font-family: fino-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.781);
  width: 100%;
  padding: 10px 0 0 0;
}



.priceInd {
  margin: 0;
  animation: premio .5s forwards linear;
}

.renewButton {
  margin: 20px 0 0 0;
  padding: 20px;
  line-height: 0;
}

@keyframes premio {
  0% {
    transform: translateY(100px);
  }

  80% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}

.slot {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.slotFoot {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.betInput {
  font-family: hydrophilia-iced, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  background-size: 20px;
  border: none;
  border-radius: 2px;
  font-size: 1.2em;
  margin: 20px;
  height: 40px;
  width: 100%;
  margin: 0 5px 0 0;
  flex: 1 1 auto;
  text-align: center;
}

.spinButton {
  cursor: pointer;
  font-family: hydrophilia-iced, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: 1.2em;
  margin: 0 0 0 5px;
  flex: 1 1 auto;
  width: 100%;
  height: 40px;
  background-color: #ffd900;
  border: none;
  border-radius: 2px;
}

.buyMoreButton {
  cursor: pointer;
  font-family: hydrophilia-iced, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: 1em;
  width: calc(100% - 20px);
  height: 30px;
  margin-bottom: 10px;
  background-color: #ffd900;
  border: none;
  border-radius: 2px;
  ;
}

.row {
  flex: 1 0 auto;
  /* margin: 0 5px 0 5px; */
  overflow: hidden;
  border: solid 2px #ffd9009c;
  background: linear-gradient(0deg, rgb(204, 203, 203) 0%, rgb(255, 255, 255)20%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 80%, rgb(204, 203, 203) 100%);
}

.ringMoving {
  /* height: 200px;
  line-height: 200px; */
  text-align: center;
  font-size: 4em;
  animation: goaround 0.2s linear infinite;
}

.ringEnd {
   /*height: 200px; 125px;*/
   /*line-height: 200px; 125px;*/
  text-align: center;
  font-size: 4em;
  animation: stop .5s ease-out forwards;
}

@keyframes goaround {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-1000px);
  }
}

@keyframes stop {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-90px)
  }

  75% {
    transform: translateY(-80px)
  }

  100% {
    transform: translateY(-85px)
  }
}


.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1e293b;
}

.st1 {
  fill: #FFFFFF;
  stroke: #89949B;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #DBDFE1;
}

.st3 {
  fill: #FFFFFF;
}

.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1e293b;
  stroke: #89949B;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
  stroke: #89949B;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949B;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949B;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
  stroke: #89949B;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st9 {
  fill: #89949B;
}

.st10 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #89949B;
}

.st11 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949B;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st12 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
}

.st13 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
  stroke: #8894A0;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st14 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #89949B;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st15 {
  fill: none;
  stroke: #89949B;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

/* COIN FLIPPING SHIT */


.content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  transition: transform 1s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.front,
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 300px;
  color: #03446A;
  text-align: center;
  font-size: 60px;
  border-radius: 5px;
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}

@keyframes flip_animation {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}


#coin {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

#coin div {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 45px rgba(255, 255, 255, .3), 0 12px 20px -10px rgba(0, 0, 0, .4);
  -moz-box-shadow: inset 0 0 45px rgba(255, 255, 255, .3), 0 12px 20px -10px rgba(0, 0, 0, .4);
  box-shadow: inset 0 0 45px rgba(255, 255, 255, .3), 0 12px 20px -10px rgba(0, 0, 0, .4);
}

.side-a {
  background-color: #bb0000;
}

.side-b {
  background-color: #3e3e3e;
}

#coin {
  transition: -webkit-transform 1s ease-in;
  -webkit-transform-style: preserve-3d;
}

#coin div {
  position: absolute;
  -webkit-backface-visibility: hidden;
}

.side-a {
  z-index: 100;
}

.side-b {
  -webkit-transform: rotateY(-180deg);

}

#coin.heads {
  -webkit-animation: flipHeads 3s ease-out forwards;
  -moz-animation: flipHeads 3s ease-out forwards;
  -o-animation: flipHeads 3s ease-out forwards;
  animation: flipHeads 3s ease-out forwards;
}

#coin.tails {
  -webkit-animation: flipTails 3s ease-out forwards;
  -moz-animation: flipTails 3s ease-out forwards;
  -o-animation: flipTails 3s ease-out forwards;
  animation: flipTails 3s ease-out forwards;
}

@-webkit-keyframes flipHeads {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }

  to {
    -webkit-transform: rotateY(1800deg);
    -moz-transform: rotateY(1800deg);
    transform: rotateY(1800deg);
  }
}

@-webkit-keyframes flipTails {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }

  to {
    -webkit-transform: rotateY(1980deg);
    -moz-transform: rotateY(1980deg);
    transform: rotateY(1980deg);
  }
}

@-webkit-keyframes flipPerpetual {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }

  to {
    -webkit-transform: rotateY(1800deg);
    -moz-transform: rotateY(1800deg);
    transform: rotateY(1800deg);
  }
}



@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }

}


@keyframes slide-right {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-middle-from-left {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(50%);
  }
}

@keyframes slide-middle-from-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(50%);
  }
}

.animate-slide {
  animation: slide 5s ease-in-out infinite ;
  /* */
}


/*
#cloud {
  .animation(cloud 9s infinite ease-in-out);
}

.keyframes(~'cloud, 0% { opacity: 0.3; left: 160px; transform:skewX(20deg); } 65% { opacity: 1; left: 114px; transform:skewX(0deg); } 100% { opacity: 0.3; left: 160px; transform:skewX(20deg); } ');

#moon {
  transform-origin: 640px 81px;
  .animation(moon-float 6s infinite ease-in-out);
}

.keyframes(~'moon-float, 0% { transform: translate(0, 20px) scale(1.1); } 65% { transform: translate(0, 0px) scale(1.0); } 100% { transform: translate(0, 20px) scale(1.1); } ');


#monkey-eye-l {
  transform-origin: 191px 257px;
  .animation(blink-l 12s infinite ease-in-out);
}

#monkey-eye-r {
  transform-origin: 205px 256px;
  .animation(blink-r 12s infinite ease-in-out);
}

#monkey-arm {
  transform-origin: 155px 298px;
  .animation(monkey-arm 4s infinite ease-in-out);
}


.keyframes(~'monkey-arm, 0% { transform: rotateZ(-10deg);  } 50% { transform: rotateZ(20deg); } 100% { transform: rotateZ(-10deg); } ');

.keyframes(~'blink-l, 0% { transform: rotateX(0deg); } 2% { transform: rotateX(80deg); } 4%,20% { transform: rotateX(0deg); } 22% { transform: rotateX(80deg); } 24%,30% { transform: rotateX(0deg); } 32% { transform: rotateX(80deg); } 34%,70% { transform: rotateX(0deg); } 72% { transform: rotateX(80deg); } 74%,100% { transform: rotateX(0deg); }');

.keyframes(~'blink-r, 0% { transform: rotateX(0deg); } 2% { transform: rotateX(80deg); } 4%,30% { transform: rotateX(0deg); } 32% { transform: rotateX(80deg); } 34%,50% { transform: rotateX(0deg); } 52% { transform: rotateX(80deg); } 54%,100% { transform: rotateX(0deg); } ');



#star1 {
  transform-origin: 643px 338px;
  .animation(star1 12s infinite linear, star1-fade 12s infinite ease-in-out);
}

#star2 {
  transform-origin: 489px 82px;
  .animation(star2 12s infinite linear, star2-fade 12s infinite ease-in-out);
}

#star3 {
  transform-origin: 316px 344px;
  .animation(star3 12s infinite linear, star3-fade 12s infinite ease-in-out);
}

#star4 {
  transform-origin: 126px 182px;
  .animation(star4 12s infinite linear, star4-fade 12s infinite ease-in-out);
}

.keyframes(~'star1, 0% { transform: scale(.5)  skewX(15deg) rotateZ(0deg); } 15% { transform: scale(1) skewX(0deg) rotateZ(360deg); } 30%,100% {  transform: scale(.5) skewX(15deg) rotateZ(720deg); } ');
.keyframes(~'star2, 0%,15% { transform: scale(.5) skewX(15deg) rotateZ(0deg); } 30% { transform: scale(1) skewX(0deg) rotateZ(360deg); } 45%,100% {  transform: scale(.5) skewX(15deg) rotateZ(720deg); } ');
.keyframes(~'star3, 0%,30% { transform: scale(.5) skewX(15deg) rotateZ(0deg); } 45% { transform: scale(1) skewX(0deg) rotateZ(360deg); } 60%,100% {  transform: scale(.5) skewX(15deg) rotateZ(720deg); } ');
.keyframes(~'star4, 0%,45% { transform: scale(.5) skewX(15deg) rotateZ(0deg); } 60% { transform: scale(1) skewX(0deg) rotateZ(360deg); } 75%,100% {  transform: scale(.5) skewX(15deg) rotateZ(720deg); } ');

.keyframes(~'star1-fade, 0% { opacity: 0;} 15% { opacity: 1; } 30%,100% { opacity: 0; } ');
.keyframes(~'star2-fade, 0%,15% { opacity: 0;} 30% { opacity: 1; } 45%,100% { opacity: 0; } ');
.keyframes(~'star3-fade, 0%,30% { opacity: 0;} 45% { opacity: 1; } 60%,100% { opacity: 0; } ');
.keyframes(~'star4-fade, 0%,45% { opacity: 0;} 60% { opacity: 1; } 75%,100% { opacity: 0; } ');

#sword-path {
  transform-origin: 458px 206px;
  .animation(tetris-float 3s infinite ease-in-out);

  //.animation(sword 3s infinite ease-out);
}


#sword {
  transform-origin: 458px 206px;

  .animation(sword 4s infinite ease-out);
}

.keyframes(~'sword, 0%,15% { transform:rotate(-40deg); } 87% { transform:rotate(50deg);  } 100% { transform:rotate(-40deg); } ');

#tetris-path {
  transform-origin: 538px 374px;
  .animation(tetris-float 5s infinite ease-in-out);
  //.animation(rotate 10s steps(1) infinite alternate); //steps(1)
}

#tetris {
  transform-origin: 538px 374px;
  //.animation(tetris-float 5s infinite ease-in-out);
  .animation(rotate 10s steps(1) infinite alternate); //steps(1)
}

.keyframes(~'rotate, 0% { transform: rotateZ(0deg); } 25% { transform: rotateZ(90deg); } 50% { transform: rotateZ(180deg); } 75% { transform: rotateZ(270deg); } 100% { transform: rotateZ(360deg); }');
.keyframes(~'tetris-float, 0% { transform: translate(0px, 0px) scale(0.90)  } 65% { transform: translate(0px, 30px) scale(1.0); } 100% { transform: translate(0px, 0px) scale(0.90); }');

#triforce {
  transform-origin: 175px 91px;
  .animation(triforce-float 3s infinite ease-in-out);
}

.keyframes(~'triforce-float, 0% { transform: translate(0px, 0px) scale(0.90)  } 65% { transform: translate(0px, 30px) scale(1.0); } 100% { transform: translate(0px, 0px) scale(0.90); } ');
*/
